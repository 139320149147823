body {
  overflow-x: hidden;
}

.site-container {
  transition: all .3s ease-in-out;
}

.site-container.active {

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0,0,0,.65);
    opacity: 0;
  }

  @media all and (max-width: 1140px) {
    transform: translateX(-100%);

    &:before {
      opacity: 1;
    }
  }

  @media all and (max-width: 499px) {
    transform: translateX(-85%);
  }
}

.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  background-color: $white;
  color: $dark;

  @media all and (max-width: 1140px) {
    width: 100%;
    height: 100vh;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    color: $dark;

    .logo {
      height: 40px;
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
    color: $dark;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    border-top: 2px solid $dark;
    color: $dark;

    @media(max-width: 350px){
      padding: 20px 30px;
    }

    a.lang-link{
      font-size: 13px;
      color: rgba($dark,.6);
    }

    .language-link {
      color: #fff;
      margin-left: 15px;

      img {
        display: block;
        max-width: 30px;
      }

      @media (max-width: 1024px) {
        margin-right: 25px;
      }
    }

    a {
      //@extend .link;
      margin-bottom: 25px;
      color: $dark;
      font-size: 18px;

      @media(max-width: 350px){
        margin-bottom: 20px;
      }
    }
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 15px;
    border-bottom: 2px solid $dark;

    a {
      margin-bottom: 15px;
      color: $dark;
      font-size: 16px;
      text-transform: uppercase;

      &.site-btn {
        color: $dark;
        padding: 5px;
        max-width: 200px;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .navigation-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;

    .subNav-container{
      position: static;
      width: 100%;
      display: flex;
      justify-content: center;
      color: $white;

      background-color: transparent;

    }

    .subNav{
      padding-left: 0;
      justify-content: center;
    }

    .subNav-link{
      margin-left: 0;
      margin-right: 10px;
      justify-content: center;
      color: $dark;

      span{
        color: $white;
        padding-top: 2px;
      }
    }

    a {
      font-size: 12px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
        color: $white;
      }
    }
  }

  .navigation-footer-social {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;


    a {
      background: linear-gradient(to bottom, #8342FB, 65%, #00FFF0);
      padding: 12px 10px;
      border-radius: 100%;
      i {
        font-size: 26px;
        color: $white;
      }
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
