// --------------------------------------------------
// Block Grid001
// --------------------------------------------------

.grid001 {
  padding: 150px 20px;
  display: flex;
  justify-content: center;

  @media(max-width: 1224px){
    padding: 50px 20px;
  }

  @media(max-width: 768px) {
    flex-direction: column;
    margin-bottom: 100px;
  }

  @media(max-width: 599px) {
    flex-direction: column;
    margin-bottom: 0;
    padding: 50px 20px;
  }

  &.is-reverted {
    .grid001-container {
      .grid001-btn {
        display: none;
      }
      .grid001-items {
        .grid-item {
          border: none;
          flex-direction: column-reverse;

          img {
            margin-bottom: 20px;
            height: 50px;
          }

          .item-heading{
            color: $dark;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .item-text {
            width: 293px;
            font-weight: normal;
            font-size: 16px;
            line-height: 180%;
            margin: 0;
            text-transform: lowercase;

            @media(max-width: 599px) {
              font-size: 14px;
            }

            p {
              min-height: 65px;
            }

            @media(max-width: 900px){
              width: auto;
            }
          }
        }
      }
    }
  }

  .grid001-container {
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .grid001-btn {
      margin-top: 50px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      padding: 15px;

    }
  }

  .grid-heading-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.align-left {
      align-items: flex-start;

      .grid001-text {
        text-align: left;
      }
    }
    &.align-right {
      align-items: flex-end;

      .grid001-text {
        text-align: right;
      }
    }
  }

  .grid001-heading {
    @extend .big-title;
    margin: 0 0 20px;
  }

  .grid001-text {
    @extend .regular-text;
    text-align: center;
    max-width: 800px;



    p {
      margin: 0;
    }
  }

  .grid001-items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;


    &.columns-4 .grid-item {
      width: calc(25% - (60px / 4));

      &:nth-child(4n+4) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: calc(50% - 10px);

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.columns-3 .grid-item {
      width: calc(33.33334% - (40px / 3));
      background-color: $white;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: calc(50% - 10px);

        &:nth-child(3n+3) {
          margin-right: 20px;


        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;

        &:nth-child(3n+3) {
          margin-right: 0;


        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }
    }

    &.columns-2 .grid-item {
      width: calc(50% - 10px);

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.empty-mode {
      &.columns-3 .grid-item {
        &:nth-child(n+7) {
          display: none;
        }
      }
      &.columns-2 .grid-item {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
  }

  .grid-item {
    //align-self: baseline;
    margin-right: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
    padding: 21px;
    min-height: 255px;

    &:first-child {
      // margin-top: 0;
    }

    .image, img {
      height: 95px;
      margin: 0;
    }

    .item-heading {
      @extend .medium-title;
      margin: 0 0 10px;
    }

    .item-text {
      @extend .regular-text;
      margin-bottom: 0;
      text-align: center;
      max-width: 300px;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;

      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: $dark;

      p {
        margin: 0;
      }

      strong {
        margin: 0;
        font-weight: 900;
        color: $dark;
      }
    }

    &.align-left {
      align-items: flex-start;

      .item-text {
        text-align: left;
      }
    }
    &.align-right {
      align-items: flex-end;

      .item-text {
        text-align: right;
      }
    }
  }

  &.grey {
    background-color: $grey;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &.dark {
    background-color: $dark;

    .grid001-heading, .grid-item .item-heading {
      color: white;
    }
  }
}
