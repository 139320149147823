.contact001 {
  @extend .site-max-width;
  max-width: 1440px;
  padding: 130px 20px;

  @media(max-width: 1024px) {
    padding: 100px 20px;
  }

  @media(max-width: 767px) {
    padding: 50px 20px;
  }

  .contact001-top {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    margin-bottom: 200px;
    max-width: 1045px;

    &.no-map{
      margin-bottom: 0;
    }

    @media(max-width: 899px) {
      flex-direction: column;
    }

    .contact001-location {
      margin-right: 300px;
      min-width: 220px;
      font-family: $fontNewText;
      color: $dark;

      @media(max-width: 899px) {
        width: 100%;
        margin-right: inherit;
        margin-bottom: 50px;
      }

      .location-town {
        font-family: $fontNewText;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $dark;
        margin-bottom: 20px;
        text-align: center;
      }

      .location-address {
        font-family: $fontNewText;
        max-width: 200px;
        margin: 0 auto;
        text-align: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: rgba($dark, .8);
        margin-bottom: 20px;

      }

      .location-link {
        font-family: $fontNewText;
        display: block;
        font-size: 16px;
        line-height: 20px;
        color: rgba($dark, .8);
        margin-bottom: 20px;
        text-align: center;
      }

      .location-btn {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        padding: 15px;
      }
    }


  }
}

.contact001-form {
  // width: 60%;
  max-width: 100%;

  @media(max-width: 899px) {
    width: 100%;
  }

  &.full-width{
    width: 100%;
    padding: 40px 20px;

    &[data-form]{
        display: none;
    }
  }

  .input-wrapper {
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    width: calc(50% - 20px);
    margin-right: 30px;


    &:nth-child(even) {
      margin-right: 0;
    }

    &:last-of-type {
      width: 100%;
    }

    input {
      display: inline-block;
      width: 100%;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(#6E6E6E, .5);

      padding-left: 0;
      color: $dark;

      &:nth-child(2n+1) {
        margin-right: 0;
      }

      &:not(:placeholder-shown), &:focus {
        & + label {
          top: -5px;
          transform: translateY(0);
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: $dark;

        }
      }
    }

    textarea {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(#6E6E6E, .5);
      height: 100px;
      padding-left: 0;

      &:not(:placeholder-shown), &:focus {
        & + label {
          top: -5px;
          transform: translateY(0);
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: $dark;

        }
      }
    }

    label {
      display: block;
      font-family: $fontNewText;
      position: absolute;
      left: 0;
      top: 30%;
      transform: translateY(-30%);
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.135px;
      color: rgba($dark, .7);
      font-weight: 600;
      pointer-events: none;
      transition: all ease-out .2s;

    }
  }

  .form-btn {
    display: block;
    margin: 0 auto;
    margin-right: 0;
    color: #fff;
    text-align: center;
    padding: 15px;
    background-color: #525252;
    border: none;
  }
}
