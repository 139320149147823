// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  a,
  div {
    display: block;
    padding: 10px 15px;

    color: $white;
    font-size: 18px;
    line-height: 23px;
    text-decoration: none;

    @extend .button-text;

    &.content-pagination-prev,
    &.content-pagination-next {
      span {
        display: block;
        width: 45px;
        height: 45px;
        background-color: #333B3F;
      }
    }

    &.content-pagination-prev {
      border-radius: 3px 0 0 3px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;

        background-color: #333B3F;
        color: $white;


        border-radius: 25px;

        &.is-disabled {
          background-color: rgba(#333B3F, .3);
          color: rgba($white, .3);
        }
      }
    }

    &.content-pagination-next {
      border-radius: 0 3px 3px 0;

      span {

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;

        background-color: #333B3F;
        color: $white;

        border-radius: 25px;

        &.is-disabled {
          background-color: rgba(#333B3F, .3);
          color: rgba($white, .3);
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      color: $colorAccent1;

      span {
        border-bottom: 1px solid $colorAccent1;
      }

    }

    &:last-child {
      // border-right: 1px $light solid;
    }
  }
}
