// --------------------------------------------------
// Block Slider002
// --------------------------------------------------

.slider001 {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media(max-width: 490px){
    padding-top: 0;
    padding-bottom: 0;
  }

  .slider001-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    max-width: 1440px;

    // @media (min-width: 576px) {
    //   max-width: 540px;
    // }
    // @media (min-width: 768px) {
    //   max-width: 720px;
    // }
    // @media (min-width: 992px) {
    //   max-width: 960px;
    // }
    // @media (min-width: 1200px) {
    //   max-width: 1140px;
    // }
  }

  .slider001-textbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .slider001-heading {
      @extend .big-title;
      max-width: 1024px;
      margin: 0;
      padding: 0;
      text-align: center;
    }

    .slider001-text {
      @extend .regular-text;
      width: 100%;
      max-width: 775px;
      padding: 0;
      text-align: center;

      @media (max-width: 1070px) {
        width: 60%;
      }

      @media (max-width: 599px) {
        width: 100%;
      }
    }

    &.align-left {
      align-items: flex-start;

      .slider001-text {
        text-align: left;
      }
    }

    &.align-right {
      align-items: flex-end;

      .slider001-text {
        text-align: right;
      }
    }
  }

  .slider001-slider-container {
    position: relative;
    display: flex;
    justify-content: center;

    .slider001-slider {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      // @media (min-width: 992px) {
      //   flex: 0 0 83.333333%;
      //   max-width: 83.333333%;
      //
      //   &.is-full {
      //     flex: 0 0 100%;
      //     max-width: 100%;
      //   }
      // }

      .flickity-viewport {
        width: 100%;
      }

      .flickity-prev-next-button {
        width: 45px;
        height: 45px;
        transition: all ease-out .2s;
        cursor: pointer;
        z-index: 1;
        border: 1px solid $dark;
        background-color: #f7f7f7;


        @media(max-width: 490px){
          width: 25px;
          height: 25px;
        }


        &.previous {
          left: 30px;

          @media(max-width: 490px){
            left: -10px;
          }

          &:before{
            content: "\E823";
            position: relative;
            top: -1px;
            font-family: fontello;
            font-size: 12px;
          }
        }

        &.next {
          right: 30px;

          @media(max-width: 490px){
            right: -10px;
          }

          &:before{
            content: "\E82B";
            position: relative;
            top: -1px;
            font-family: fontello;
            font-size: 12px;
          }
        }

        &:hover:not(:disabled) {
          transform: translateY(-50%) scale(1.2);
          opacity: 1;
          box-shadow: 0 0 10px 0 rgba($dark, .2);
        }

        &:disabled {
          opacity: .5;
        }
      }

      .flickity-page-dots {
        bottom: -40px;

        .dot {
          background-color: $light;
          transition: background-color ease-out .2s;

          &.is-selected {
            background-color: $colorAccent1;
          }
        }
      }
    }

    .slide-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1024px) {
        flex-direction: column !important;
      }
    }

    .slide-image-box {
      position: relative;
      width: 100%;
      height: 375px;
      background-color: transparent;


      .image, img {
        display: none;
        width: 100%;

        @media (max-width: 599px) {
          width: auto;
          height: 430px;
        }
      }

      .video-overlay {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($dark, .5);

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: white;
          font-size: 60px;
          transition: all ease-out .2s;
        }

        &:hover {
          i {
            transform: translate(-50%,-50%) scale(1.2);
            text-shadow: 0 0 20px rgba($dark, .5);
          }
        }
      }

      .video-embed {
        display: none;
      }
    }

    .slide-text-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      max-width: 730px;
      background-color: #f7f7f7;
      padding: 50px;
      left: 50%;
      top: 25%;
      transform: translate(-50%, -25%);
      margin-top: 40px;
      border: 1px solid $dark;

      @media(max-width: 768px) {
        max-width: 300px;
        padding: 20px;
        left: 100px;
      }

      @media(max-width: 599px) {
        max-width: 250px;
        transform: translate(0, 0);
      }

      @media(max-width: 490px){
        max-width: 100%;
        left: 0;
        top: 0;
      }

      .slide-heading {
        @extend .medium-title;
        margin-top: 0;
      }

      .slide-text {
        @extend .regular-text;

        p {
          margin: 0;
          font-weight: normal;
          font-size: 18px;
          line-height: 180%;
          text-align: left;
          color: $dark;

          @media(max-width: 768px) {
            font-size: 14px;
          }

        }
      }

      .site-btn {
        margin-top: 40px;
        @extend .site-btn;
      }

      &.align-left {
        align-items: flex-start;

        .slide-text {
          text-align: left;
        }
      }

      &.align-right {
        align-items: flex-end;

        .slide-text {
          text-align: right;
        }
      }
    }
  }

  // Options
  &.imageBelowTheText {
    .slider001-slider-container .slide-container {
      flex-direction: column-reverse;

      .slide-text-box {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }

  &.imageOnTheLeft {
    .slider001-slider-container .slide-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .slide-image-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      .slide-text-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (min-width: 992px) {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
      }
    }
  }

  &.imageOnTheRight {
    .slider001-slider-container .slide-container {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      .slide-image-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      .slide-text-box {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0;

        @media (min-width: 576px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (min-width: 992px) {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
      }
    }
  }

  &.grey {
    background-color: $grey;
  }

  &.dark {
    background-color: $dark;

    .slider001-textbox .slider001-heading, .slider001-slider-container .slide-text-box .slide-heading {
      color: white;
    }
  }
}
