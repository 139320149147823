// --------------------------------------------------
// Block Split001
// --------------------------------------------------

.split001 {
  //border-top: 1px solid rgba(255, 255, 255, .1);
  //border-bottom: 1px solid rgba(255, 255, 255, .1);

  &.images-rounded{
    padding: 180px 0;
    padding-top: 0;

    .split001-wrapper{
      margin-bottom: 180px;

      @media(max-width: 1024px){
        margin-bottom: 0;
      }
    }

    .split001-wrapper-container{
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .split001-image-wrapper{
      width: 380px;
      height: 380px;
      border-radius: 100%;
      overflow: hidden;
      min-height: inherit;

      @media(max-width: 500px){
        width: 260px;
        height: 260px;
      }

      img{
        display: none;
        width: auto;
      }
    }

    .split001-content-container{
      width: 50%;
      max-width: none !important;

      .split001-heading:after{
        display: none;
      }

      .split001-heading, .split001-text {
        @media(max-width: 1024px){
            margin-bottom: 10px !important;
            text-align: center !important;
        }
      }
    }
  }

  .split001-wrapper {
    @extend .site-max-width;
    padding-left: 0;
    padding-right: 0;

    &:nth-child(even) {
      .split001-wrapper-container {
        flex-direction: row-reverse;
      }
    }
  }

  .split001-wrapper-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    // Row Alignment
    &.imageLeft {
      flex-direction: row !important;
    }

    &.imageRight {
      flex-direction: row-reverse !important;
    }

    // Text Alignment
    &.centered {
      text-align: center !important;
    }

    &.left {
      text-align: left !important;
    }

    &.right {
      text-align: right !important;
    }

    // Theme
    &.light {
      background-color: white;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: $dark;
      }
    }

    &.grey {
      background-color: $grey;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: $dark;
      }
    }

    &.dark {
      background-color: $dark;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: white;
      }
    }
  }

  .split001-text-wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: 1024px) {
      width: 100%;
    }

    .split001-content-container {
      width: 80%;
      max-width: 350px;
      margin: 0 auto;

      @media(max-width: 1024px) {
        padding: 60px 0 calc(60px + 30px); // 30px for the little green bar
      }

      @media(max-width: 599px) {
        padding-bottom: 0;
      }

      .split001-heading {
        position: relative;
        font-family: $fontNewText;
        margin-top: 0;
        margin-bottom: 60px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.8;
        align-items: center;
        letter-spacing: 0.1em;
        color: $dark;
        text-align: left;

        @media(max-width: 767px){
          margin: 0 0 10px;
          font-size: 25px;
        }

        @media (max-width: 599px) {
          width: 100%;
          font-size: 16px;
        }

        &:after{
          content: '';
          position: absolute;
          bottom: -30px;
          left: 0;
          right: 80%;
          height: 2px;
          width: 50px;
          background: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);

          @media (max-width: 599px) {
            bottom: -8px;
          }
        }
      }

      .split001-text {
        @extend .regular-text;
        position: relative;
        margin: 0 auto;
        max-width: 275px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 180%;
        text-align: left;
        color: $dark;

        @media(max-width: 1024px){
          font-size: 16px;
        }

        @media(max-width: 767px){
          margin: 0 0 20px;
        }

        @media (max-width: 599px) {
          width: 100%;
          font-size: 14px;
        }

        &.no-bar{
          max-width: none;

          &::after{
            display: none;
          }
        }

        &:after{
          content: '';
          position: absolute;
          bottom: -30px;
          left: 0;
          right: 80%;
          height: 2px;
          width: 60px;
          background: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
        }

        ul {
          margin: 15px auto 0;
          padding-left: 35px;
          list-style-image: url(/img/site/check.svg);

          @media(max-width: 1024px){
            top: 10px;
            width: 100%;
            padding-left: 0;
          }

          li {
            line-height: inherit;
            margin-bottom: 10px;

            @media(max-width: 1024px){
              text-align: left;
            }

            strong {
              color: $dark;
            }
          }
        }
        p{
          margin: 0;
          // padding-left: 40px;
        }
      }

      .site-btn {
        @extend .site-btn;
      }
    }
  }

  .split001-image-wrapper {
    position: relative;
    width: 50%;
    min-height: 655px;

    @media(max-width: 1024px) {
      width: 100%;
      min-height: 300px;
    }

    img {
      display: block;
      width: 100%;
    }

    .video-overlay {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($dark, .5);

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        font-size: 60px;
        transition: all ease-out .2s;
      }

      &:hover {
        i {
          transform: translate(-50%,-50%) scale(1.2);
          text-shadow: 0 0 20px rgba($dark, .5);
        }
      }
    }

    .video-embed {
      display: none;
    }

    .gallery-slides {
      .gallery-slide {
        width: 100%;

        .image {
          width: 100%;
        }
      }

      .flickity-prev-next-button {
        width: 50px;
        height: 50px;
        opacity: .75;
        transition: all ease-out .2s;

        &.previous {
          left: 20px;
        }

        &.next {
          right: 20px;
        }

        &:hover {
          transform: translateY(-50%) scale(1.2);
          opacity: 1;
          box-shadow: 0 0 20px 0 rgba($dark, .5);
        }
      }
    }
  }

  // Options
  &.full-width {
    padding: 0;
    margin: 45px 0;

    .split001-wrapper {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.space-between {
    .split001-wrapper:not(:first-child) {
      margin-top: 100px;
    }
  }

  // Text Alignment
  &.closeToThePicture {
    .split001-wrapper {
      .split001-wrapper-container {
        text-align: left;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          text-align: right;
        }
      }
    }
  }

  &.awayFormThePicture {
    .split001-wrapper {
      .split001-wrapper-container {
        text-align: right;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          text-align: left;
        }
      }
    }
  }

  // Row Alignment
  &.imageRight {
    .split001-wrapper {
      .split001-wrapper-container {
        flex-direction: row-reverse;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          flex-direction: row;
        }
      }
    }


    // Text Alignment
    &.closeToThePicture {
      .split001-wrapper {
        .split001-wrapper-container {
          text-align: right;
        }

        &:nth-child(even) {
          .split001-wrapper-container {
            text-align: left;
          }
        }
      }
    }

    &.awayFormThePicture {
      .split001-wrapper {
        .split001-wrapper-container {
          text-align: left;
        }

        &:nth-child(even) {
          .split001-wrapper-container {
            text-align: right;
          }
        }
      }
    }
  }

  // Theme
  &.grey {
    background-color: $grey;
  }

  &.dark {
    background-color: $dark;

    .split001-text-wrapper .split001-content-container {
      .split001-heading {
        color: white;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #f7f7f7;

    img{
      margin-right: 12px;
    }

    .split-btn{
      margin-right: 20px;
      padding-bottom: 5px;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.05em;
      color: #1A1A1A;
      transition: scale .3s ease-in-out;

      @media(max-width: 599px) {
        font-size: 14px;
      }

      &:hover{
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
      }

      &:not(:hover){
        border-bottom: 1px solid rgba($dark, 1);
      }
    }

    .split-btn:hover + .right-arrow{
      opacity: 1;
      transition: opacity .5s;
    }
  }
}

.products {
  .block-intro001 {
    padding-top: 0;
    border-top: none;
  }
  .split001 {
    padding-bottom: 0;
    border-bottom: none;
    .split001-wrapper {
      background-color: #fff;
      margin-left: 0;
      margin-right: 0;
      padding-top: 100px;
      padding-bottom: 100px;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;

      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      .split001-heading {
        font-family: Magnita;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 140%;
        letter-spacing: 0.025em;

        /* Grey tone */

        color: #1A1A1A;

        @media(max-width: 767px){
          margin: 0 0 10px;
          font-size: 25px;
        }

        @media (max-width: 599px) {
          width: 100%;
          font-size: 16px;
        }
      }


      .split001-text {



        p {
          font-family: Manrope;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 180%;
          color: $dark;

          @media(max-width: 767px){
            margin: 0 0 20px;
          }

          @media (max-width: 599px) {
            width: 100%;
            font-size: 14px;
          }

          strong {
            color: $dark;
          }


          em {
            position: relative;
            display: block;
            font-family: Manrope;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.05em;
            color: $dark;
            margin-top: 38px;

            @media(max-width: 767px){
              margin: 0 0 20px;
            }

            @media (max-width: 599px) {
              width: 100%;
              font-size: 14px;
            }

            &:before {
              content: '';
              position: absolute;
              top: -8px;
              left: 0;
              width: 30px;
              height: 2px;
              background: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);

              @media (max-width: 765px) {
                top: 10px;
                left: 50%;
                transform: translate(-50%, -15px);
              }
            }

          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          color: $dark;

          li {
            width: calc(100% / 2 - 19px);
            margin-right: 38px;
            color: $dark;

            strong {
              color: $dark;
              font-weight: 600;
            }

            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
