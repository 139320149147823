// --------------------------------------------------
// Intro 001
// --------------------------------------------------
circle {
  max-width: 100px;
  fill: #2f3d4c;
}
.block-intro001 {
  position: relative;
  padding: 50px 0;
  max-width: 100%;
  // background-color: $colorAccent1;

  @media(max-width: 767px){
    //padding: 80px 0 50px;

    padding-bottom: 20px;

    &:after{
      top: calc(100% - 50px) ;
    }
  }

  &.with-icons:after{
    display: none;
  }

  &.top-padding-low{
    padding-top: 50px;
    //min-height: 500px;

    @media(max-width: 767px){
      padding-top: 20px ;
      min-height: 0;
    }

    .intro-title {
      margin-top: 0;
    }
  }

  &.isBoxed:after{
    display: none;
  }

  &.longbar {
    position: relative;
    //min-height: 700px;
    //padding: 230px 0 100px;
    padding-bottom: 150px;

    @media(max-width: 767px) {
      padding-top: 50px;
      min-height: 0;
    }

    .intro-title {
      margin: 0;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 100px;
      width: 2px;
      background: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
    }
  }

  &.middle-bar{
    background-color: #F7F7F7;
    padding-top: 50px;
    padding-bottom: 0;

    .container-loader {
      display: none;
    }

    .intro-title{
      margin-top: 0px;
      margin-bottom: 0;

    }

    &:before {
      display: none;
    }
  }

  &.middle-bar:after{
    top: calc(100% - 196px);
  }

  &.longbar:after{
    top: calc(100% - 278px);
  }

  &.isBoxed{
    padding-top: 0;
    padding-bottom: 0;

    .block-wrapper {
      position: relative;
      background-color: #F7F7F7;
      max-width: 100%;
      padding: 200px 0;

      @media(max-width: 1170px){
        width: 90%;
      }

      &:before {
        position: absolute;
        content: '';
        width: 80%;
        height: 60%;
        background-color: transparent;
        border: 1px solid $dark;

      }

      .intro-title {
        margin-top: 50px;
      }
    }

    .loader-half-circle-rotation{
      top: 20px ;

      @media (max-width: 400px){
        top: 45px;
      }

      @media (max-width: 311px){
        top: 68px;
      }
    }

    .intro-title{
      margin: 0;

      &:before{
        top: -148px;
      }

      &:after{
        top: -160px;
      }
    }
  }

  .block-wrapper {
    @extend .site-max-width;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



    .site-btn {
      margin-top: 50px;
      z-index: 5;
    }

    .scroll-down-indicator {
      position: absolute;
      left: 50%;
      bottom: -70px;
      transform: translateX(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      color: $white;
      height: 70px;
      width: 70px;
      margin: 0 auto;
      border-radius: 70px;
      padding: 15px;
      z-index: 1;
      border: 1px solid white;
      animation: button_pulse 2s ease-out infinite;

      @media(max-width: 767px){
        bottom: -90px;
      }

      // &:hover {
      //   animation: button_pulse 2s ease-out infinite;
      // }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        border: 1px solid #fff;
        font-size: 10px;
        font-weight: bold;
        color: $colorAccent1;
        background-color: white;

        @media(max-width: 767px){
          background-color: $white;
          color: $colorAccent1;
        }

        &:before {
          width: 2em;
        }
      }
    }

  }

  .intro-subHeading{
    font-family: $fontText;
    margin-top: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 184%;
    text-align: center;
    color: $white;

    @media(max-width: 400px){
      line-height: 110%;
    }
  }

  .intro-title {
    @extend .big-title;
    position: relative;
    max-width: 880px;
    margin: 0;
    margin-top: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.025em;
    color: $black;

    @media(max-width: 767px){
      font-size: 25px;
    }

    @media (max-width: 599px) {
      width: 100%;
      font-size: 16px;
    }

    &.no-icon {
      margin-top: 0;
    }


    &.no-icon:after, &.no-icon:before,{
      content: '' ;
      display: none;
    }

    &.with-icons{
      margin-bottom: 80px;
    }

    &.long-title{
      max-width: 100%;
    }
  }

  .icons-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    width: 100%;
    max-width: 650px;
    flex-direction: row;
    justify-content: space-around;
    color: $white;

    .icon-item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media(max-width: 530px){
        width: 50%;
      }
      .icon-heading {
        font-family: $fontNewText;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;

        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #1A1A1A;

        @media (max-width: 599px) {
          width: 100%;
          font-size: 16px;
        }


      }

      img{
        display: block;
        max-width: 80px;
        opacity: .7;
      }
    }
  }

  .intro-content {
    @extend .regular-text;
    font-family: $fontNewText;
    margin: 0;
    margin-top: 50px;
    width: 100%;
    max-width: 540px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    text-align: center;
    letter-spacing: 0;
    color: $black;

    @media(max-width: 767px){
      margin-top: 20px;
    }

    @media (max-width: 599px) {
      width: 100%;
      font-size: 14px;
    }

    &.with-icons{
      max-width: 350px;
    }

    p {
      margin: 0;
      color: $black;

      strong {
        font-weight: 600;
        color: $black;
      }
    }

    ul,
    ul li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }
}

.container-loader{
  position: relative;
	display: flex;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	transition: 0.5s;

  .circle{
  	display: block;
  	margin: 0 auto;
  	height: 60px;
  	width: 60px;
  	border-radius: 50%;
  	background: #8342FB;
  	margin: 10px;
  	text-align: center;
  	display: flex;
  	align-content: center;
  	justify-content: center;
  	flex-direction: column;
    transition: all 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  }
  span{
  	display: block;
  	margin: 0 auto;
  	background: #222;
  	width: 95px;
  	height: 95px;
  	border-radius: 50%;
  	padding: 25px;
  	line-height: 95px;
  	font-size: 45px;
  	font-family: 'futura', 'arial', sans-serif;
  	color: white;
  }
  .value{
      // background-image: linear-gradient(0deg, transparent 0%, #0AC00A 0%), linear-gradient(0deg, white 0%, transparent 0%);
  }

  .circle-on{
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    width: 58px;
    height: 58px;
    position: absolute;
    // background-color: #0ac00a;
    border-radius: 100%;
    left: 11px;
    bottom: 11px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

}
