// --------------------------------------------------
// Content
// --------------------------------------------------

.content {

}

// --------------------------------------------------
// Section Hero
// --------------------------------------------------

.section-hero {
  // height: 630px;

  .main-carousel {
    // height: 100%;

    .carousel-cell {
      position: relative;
    }

    .carousel-cell-content {
      display: flex;
      align-items: center;
      min-height: 600px;
      height: 100%;
      padding: 80px 165px;
      padding-top: 120px;
      text-align: left;
      color: #fff;
      transition: all 0.45s ease;

      z-index: 4;

      overflow: hidden;

      @media only screen and (max-width: 767px) {
        height: auto;
        min-height: 450px;
        padding: 75px 20px;
        padding-top: 75px;
      }

      &.has-effect-blue {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $colorAccent1;
          z-index: 5;
          opacity: .75;
        }
      }

      &.has-effect-grey {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $dark;
          z-index: 1;
          opacity: .75;
        }
      }


      .separator-content-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        z-index: 2;

        &.title-only {

          @media(max-width: 767px) {
            min-height: inherit;
          }

          .carousel-cell-title {
            // color: red;
          }
        }
      }
    }
    .scroll-down-indicator {
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      color: $black;
      height: 70px;
      width: 70px;
      margin: 0 auto;
      border-radius: 70px;
      padding: 15px;
      border: 1px solid rgba($colorAccent1, .8);
      z-index: 1;
      animation: button_pulse 2s ease-out infinite;

      // &:hover {
      //   animation: button_pulse 2s ease-out infinite;
      // }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background-color: $white;
        font-size: 10px;
        font-weight: bold;

        &:before {
          width: 2em;
        }
      }
    }

    .carousel-cell-title {
      margin-bottom: 50px;
      margin-top: 0;
      max-width: 820px;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 184.64%;
      letter-spacing: 0.025em;
      width: 100%;
      // letter-spacing: 0;

      @media only screen and (max-width: 1099px) {
        top: 0;
        font-size: 22px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 1.2;
      }

      strong{
        font-weight: 900;
      }
    }

    .carousel-cell-text {
      width: 100%;
      font-family: $fontNewText;
      font-weight: normal;
      font-size: 18px;
      line-height: 180%;
      text-align: left;
      color: #fff;

      p {
        margin-top: 0;
        margin-bottom: 24px;
      }


      @media only screen and (max-width: 699px) {
        font-size: 14px;
      }
    }

    .site-btn {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      padding: 25px 45px;

      color: #fff;
      border-color: #fff;
      font-size: 14px;

      font-family: $fontTitleSerif;
      // letter-spacing: 0;
      text-transform: uppercase;

      @media only screen and (max-width: 1349px) {
        bottom: 25px;
      }

      @media only screen and (max-width: 1099px) {
        bottom: 0;
      }

      @media only screen and (max-width: 767px) {
        padding: 15px 25px;
        font-size: 12px;
      }

      @media only screen and (max-width: 699px) {
        padding: 15px 15px;
      }

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }

    img.main {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: block;
      width: auto;
      height: 100%;
    }
  }

  &.no-text {
    .main-carousel .carousel-cell-title {
      min-width: 300px;
      margin: 0;
      padding: 0;
      font-family: $fontTitle;
      font-size: 32px;
      color: #fff;

      @media only screen and (max-width: 1099px) {
        font-size: 22px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .carousel-cell-text {
      display: none;
    }
  }

  img.hero-pattern {
    position: absolute;
    top: auto;
    left: auto;
    left: 0;
    bottom: 0;
    height: auto;
    filter: none;
    z-index: 4;

    @media only screen and (max-width: 1099px) {
      width: 110px;
    }

    @media only screen and (max-width: 699px) {
      display: none;
    }
  }
}

@keyframes button_pulse {
  0% {
    transform: scale(1) translateX(-50%);
    border: 1px solid rgba($colorAccent1, .8);
  }
  75% {
    transform: scale(.9) translateX(-55%);
    border: 1px solid rgba($colorAccent1, .4);
  }
  100% {
    transform: scale(1) translateX(-50%);
    border: 1px solid rgba($colorAccent1, .8);
  }
}
