.support {
  .section-hero {
    .main-carousel {
      .carousel-cell {
        .carousel-cell-content {
          min-height: 300px;

          .separator-content-wrapper {
            min-height: 200px;

            .scroll-down-indicator {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0);
              animation: button_pulse 2s ease-out infinite;

            }
          }
        }
      }
    }
  }

  .accordion-wrapper {
    @extend .site-max-width;

    max-width: 815px;

    padding: 50px 20px;

    @media(max-width: 1024px) {
      padding: 30px 20px;
    }

    @media(max-width: 767px) {
      padding: 20px 20px;
    }
    .accordion-row {
      color: $dark;

      .accordion-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        padding: 20px;
        background-color: $white;
        border: 1px solid $dark;
        transition: all .3s ease-in-out;

        &.is-active {
          background-color: $dark;
          color: $white;
          border-top: 1px solid $white;

          i,
          span {
            color: $white;
          }

        }

        i {
          margin-left: auto;

          &.close {
            transform: rotate(90deg);
          }
        }

        p {
          margin: 0;
        }

        span {
          margin-right: 20px;
          color: $dark;
        }

        span,
        i {
          color: $colorAccent1;

          &:before {
            font-weight: bold;
          }
        }


      }

      .accordion-text {
        display: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        background-color: rgba(43, 43, 43, 0.4);
        padding: 40px 50px;
        flex-direction: column;


        p {
          margin-right: 20px;

          &:nth-child(2n+2) {
            margin-right: 0;
          }

          @media(max-width: 767px) {
            width: 100%;
          }
        }

        &.is-active {
          display: flex;
          color: $white;
          background-color: $dark;
          border-top: 1px solid $white;

          @media(max-width: 767px) {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@keyframes button_pulse_support {
  0% {
    transform: translate(-50%, 0), scale(1);
    border: 1px solid rgba($colorAccent1, .8);
  }
  75% {
    transform: translate(-50%, 0), scale(.9);
    border: 1px solid rgba($colorAccent1, .4);
  }
  100% {
    transform: translate(-50%, 0), scale(1);
    border: 1px solid rgba($colorAccent1, .8);
  }
}
