// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'Magnita', 'Raleway', 'sans-serif';
$fontTitleSerif: 'Raleway', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontText: 'Source Sans Pro', 'sans-serif';
$fontTextLight: 'Source Sans Pro', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontNewText: 'Manrope','Raleway', 'sans-serif';

// Accents Colors
$colorAccent1: #8342FB;
$colorAccent2: #8342FB;
$colorAccent3: #8342FB;
$colorAccent4: #8342FB;

$colorAccent5: #1A1A1A;

$colorTextDark: #53415E;
$colorTextLight: rgba(255, 255, 255, .70);

$colorMulti: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);

// Blocks and Text
$dark: #1A1A1A;
$grey: #f7f7f7;
$light: #A0ACB9;
$lighter: #E5EAEF;
$alternateWhite: #f6f8fc;
$white : #fff;
$black : #000;


@font-face {
  font-family:'Magnita';
  src: url('../fonts/magnita.eot');
  src: url('../fonts/magnita.eot?#iefix') format('embedded-opentype'),
  url('../fonts/magnita.woff2') format('woff2'),
  url('../fonts/magnita.woff') format('woff'),
  url('../fonts/magnita.ttf') format('truetype'),
  url('../fonts/magnita.otf') format('opentype'),
  url('../fonts/magnita.svg#Magnita') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
