// --------------------------------------------------
// Footer
// --------------------------------------------------

.main-footer {
  position: relative;
  background: $colorAccent1;
  color: #fff;
  font-family: $fontTextLight;

  z-index: 1;

  @media only screen and (max-width: 1639px) {
    background-position: right -5% top;
  }

  @media only screen and (max-width: 1514px) {
    background: $colorAccent1;
    background-image: none;
  }

  .socials {
    position: absolute;
    top: 40px;
    right: 0;
    font-size: 20px;

    a {
      margin-right: 0;

      @media only screen and (max-width: 1349px) {
        font-size: 20px;
      }
    }
  }

  .logo {
    position: absolute;
    bottom: 0;
    left: 75px;
    transform: translate3d(0, 5px, 0);
    padding: 0;
    margin-right: auto;

    @media only screen and (max-width: 1349px) {
      left: 45px;
    }

    @media only screen and (max-width: 1099px) {
      left: 25px;
    }

    @media only screen and (max-width: 767px) {
      display: none;
      position: relative;
      left: 0;
      margin: 0 auto;
    }

    img {
      width: 245px;
    }
  }
}

.footer-nav {
  @extend .site-max-width;

  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 75px;
  padding-bottom: 20px;
  font-size: 16px;

  @media only screen and (max-width: 1349px) {
    // padding: 45px 0 25px 0;
    padding-top: 45px;
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  a {
    display: inline-block;
    margin-right: 75px;
    font-size: 14px;

    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent3;
    }

    @media only screen and (max-width: 1349px) {
      margin-right: 25px;
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      margin-right: 15px;
      margin-left: 15px;
      line-height: 1.5;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-copy {
  @extend .site-max-width;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 55px;
  line-height: 1;
  // border-top: 1px solid rgba(#fff, 0.10);
  font-size: 12px;

  @media only screen and (max-width: 1199px) {
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    line-height: 1.3;
  }

  a {
    display: inline-block;
    padding-right: 45px;
    font-size: 14px;

    @media only screen and (max-width: 1349px) {
      padding-right: 25px;
    }

    @media only screen and (max-width: 899px) {
      display: none;

      &:nth-child(4),
      &:nth-child(5) {
        display: inline-block;
      }
    }



    &:last-child {
      padding-right: 0;
    }
  }
}


//Footer
.site-footer {
  background-color: $black;
  padding: 40px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, .2);

  @media (max-width: 699px) {
    padding: 40px 20px;
  }

  .footer-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: 1100px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .footer-logo {
    max-width: 200px;
    max-height: 100%;
    width: 100%;
    height: auto;
  }


  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 765px) {
      text-align: center;
    }

    &:last-of-type {
      align-items: flex-end;

      .footer-title {
        width: 100%;
        text-align: left;

        @media (max-width: 1100px) {
          text-align: center;
        }
      }
    }

    @media (max-width: 1100px) {
      align-items: center;
      width: 100%;
      margin-bottom: 40px;

      &:last-of-type {
        align-items: center;
        margin-bottom: 0;
      }
    }
  }

  .footer-title {
    font-family: $fontNewText;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 180%;
    color: #FFFFFF;
    margin: 0 0 20px;
  }

  .footer-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    color: $white;
    max-width: 250px;

    p {
      margin: 0;
    }

    @media (max-width: 1100px) {
      text-align: center;
    }
  }

  .footer-copyright {
    display: flex;
    flex-wrap: wrap;
    //flex-direction: column;
    margin-top: 20px;

    @media (max-width: 1100px) {
      justify-content: center;
    }
  }

  .footer-legal {
    display: block;
    width: 100%;
    margin: 0 10px 0 0;
    padding-right: 10px;
    font-family: $fontText;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 13px;

    @media (max-width: 1100px) {
      text-align: center;
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .footer-rights {
    display: inline-block;
    color: $white;
    font-weight: 500;
    transition: all ease-out .2s;
    padding-right: 10px;
    margin-right: 10px;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-right: 1px solid $white;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    @media (max-width: 1023px) {
      border-right: none;
      //padding-right: 0;
      //margin-right: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-title {
    border-bottom: 0;


  }

  .nav-link {
    display: inline-block;
    background: linear-gradient(to bottom, #8342FB, 65%, #00FFF0);
    padding: 12px 10px;
    border-radius: 100%;
    margin-right: 15px;
    color: $white;
    transition: all .1s ease-in-out;

    i {
      margin: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.footer-socials {
  min-height: 53px;
  min-width: 170px;
}
