.careers-section {
  @extend .site-block;
  color: $dark;

  &.careers-entry{
    padding: 100px 0;
  }

  .careers-wrapper{
    @extend .site-max-width;
    max-width: calc(730px + 75px * 2);
  }

  .big-title {
    margin: 0 0 20px;
    font-family: $fontTitle;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    color: $dark;
  }

  .careers-intro {
    font-family: $fontNewText;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .careers-listing {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .careers-item {
    padding: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, .3);

    @media (max-width: 400px) {
      padding: 20px 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .careers-link{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $colorAccent1;

    @media (max-width: 400px) {
      margin-top: 10px;
    }

    &:after{
      content: "\E82B";
      margin-left: 15px;
      font-family: fontello;
      font-size: 12px;
      color: $colorAccent1;
    }
  }

  .infos-wrapper{
      display: flex;
      flex-direction: column;
  }

  .careers-infos {
    display: flex;
    align-items: center;
  }

  .careers-name {
    margin: 0 0 15px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    @media (max-width: 400px) {
      width: 100%;
      margin: 0;
    }
  }

  .careers-type{
    margin: 0 15px 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #7C7C7C;

    @media (max-width: 400px) {
      margin: 0 5px 0 0;
    }
  }

  .slash{
    margin: 0 15px 0 0;
    color: $colorAccent1;
    font-size: 16px;
    line-height: 1.2;

    @media (max-width: 400px) {
      margin: 0 5px 0 0;
    }
  }

  .apply-section{
    width: 100%;
    padding: 20px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #1A1A1A;

    @media(max-width: 400px){
      flex-direction: column;
    }

    .apply-heading{
      margin: 0 0 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }

    .apply-text{
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #C4C4C4;
    }

    .site-btn{
      padding: 15px;

      @media(max-width: 400px){
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  .contact001-form{
    @extend .site-max-width;
    max-width: 730px;
  }
}

.contact-form-wrapper {
  background-color: #EBEBEB;

  .contact-form {
    width: 100%;
    max-width: 1280px;
    padding: 70px 20px;
    margin: 0 auto;

    @media(max-width: 700px) {
      padding: 40px 20px;
    }

    .big-title {
      margin-bottom: 80px;

      @media(max-width: 700px) {
        margin-bottom: 40px;
      }
    }
  }
}

.job-description{
  @extend .site-block;
  padding: 100px 0;
  color: $white;

  .job-description-wrapper{
    @extend .site-max-width;
    max-width: calc(730px + 75px * 2);

    .job-description-text{
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.8;

      h2{
        margin: 0 0 50px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
      }

      strong{
        font-weight: 900;
      }
    }
  }
}

.benefits{
  @extend .site-block;
  color: $white;

  .benefits-wrapper{
    @extend .site-max-width;
    max-width: calc(730px + 75px * 2);
  }

  .benefits-heading {
    margin: 0 0 50px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
  }
  .benefits-text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.8;

    strong{
      font-weight: 900;
    }
  }
  .benefits-list{
    padding-left: 22px;
    list-style: none;

    li {
      // font-size: 14px;
      // line-height: 30px;
      // letter-spacing: .5px;
      // color: $white;
      // font-weight: 400;

      &:before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        /* opacity: .8; */
        font-variant: normal;
        text-transform: none;
        content: "\E816";
        margin-right: 20px;
        color: $white;

        @media(max-width: 900px) {
          margin-right: 10px;
        }
      }
    }
  }
  .benefits-list-items{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-size: 18px;
    line-height: 1.8;



    // img{
    //   color: $colorAccent1;
    //   margin-right: 20px;
    //   margin-top: 5px;
    // }

    strong{
      font-weight: 900;
    }
  }
}
.jobImage{
  width: 100%;

  img{
    width: 100%;
  }
}

.jobEntry-heading-wrapper{
  @extend .site-block;
  position: relative;
  width: 100%;
  padding-top: 0;
  padding-bottom: 75px;

  .jobEntry-heading{
    position: relative;
    max-width: 730px;
    margin: 0 auto;
    font-weight: bold;
    font-size: 38px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    &:after{
      content: '';
      position: absolute;
      bottom: -20px;
      left: 44%;
      right: 44%;
      border: 1px solid $colorAccent1;
    }
  }
}

.jobForm{
  @extend .site-block;
  position: relative;
  padding-top: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);

  &:before{
    content: '';
    position: absolute;
    bottom: calc(100% - 50px);
    right: 50%;
    top: -50px;
    border: 1px solid $white;
    opacity: .5;
  }

  .container-loader{
    width: 80px;
    margin: 0 auto 50px;

    .circle-on{
      background-color: $black;
    }
  }

}



.careers-listing .contact001-form{
  @extend .site-max-width;
  max-width: calc(730px + 75px * 2);
  background-color: $black;

  @media(max-width: 360px){
    padding: 0;
  }

  .input-wrapper{

    @media(max-width: 599px) {
      width: 100%;
    }

    &:last-of-type{
      margin-top: 50px;
    }

    input {
      display: inline-block;
      width: 100%;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba($grey, .5);

      padding-left: 0;
      color: $white;

      &:nth-child(2n+1) {
        margin-right: 0;
      }

      &:not(:placeholder-shown), &:focus {
        & + label {
          top: -5px;
          transform: translateY(0);
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: $colorAccent1;

        }
      }
    }

    textarea {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba($grey, .5);
      height: 50px;
      padding-left: 0;

      &:not(:placeholder-shown), &:focus {
        & + label {
          top: -5px;
          transform: translateY(0);
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: $colorAccent1;

        }
      }
    }

    label {
      display: block;
      position: absolute;
      left: 0;
      top: 30%;
      transform: translateY(-30%);
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.135px;
      color: rgba($white, .7);
      font-weight: 600;
      pointer-events: none;
      transition: all ease-out .2s;
      font-family: $fontNewText;

    }

  }

}

.input-file-wrapper{
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 30px;
  display: inline-block;
  height: 45px;
  width: calc(50% - 20px);
  color: rgba($white, .7);
  border: 1px dotted rgba($white, .7);
  border-radius: 3px;

  @media(max-width: 767px) {
    width: 100%;
  }

  &:hover{
    background-color: rgba($white, .1);
  }

  &:nth-child(2n+1){
    margin-right: 0;
  }

  .input-file-text{
    text-align: center;
    margin-top: 12px;
    font-size: 12px;
    line-height: 1.5;

    .icon-subtract{
      margin-right: 25px;
    }

    strong{
      font-weight: 900;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  label {
    display: block;
    position: absolute;
    top: -25px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.135px;
    color: rgba($white, .7);
    font-weight: 600;
    pointer-events: none;
    transition: all ease-out .2s;
  }

  input[type="file"] {
    position: absolute;
    height: 45px;
    width: 100%;
    opacity: 0;

    &:hover{
      cursor: pointer;
    }
  }
}
