// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  z-index: 5;
  display: inline-block;
  padding: 12px 15px;
  border: 1px solid $black;
  background-color: $white;
  color: $black;
  font-family: $fontNewText;

  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;

  text-align: center;
  letter-spacing: 0.05em;

  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;

  &:hover{
    background-color: $black;
    color: $white;
  }

  &.is-revert {
    border: 1px solid $white;
    background-color: transparent;
    color: $white;

    &:hover{
      background-color: $black;
    }
  }

  &.is-dark {
    border: 1px solid $dark;
    background-color: $dark;
    color: $white;

    &:hover{
      background-color: transparent;
      color: $dark;
    }
  }

  &.is-outlined {
    padding: 15px 25px;
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent3;
      color: $colorAccent3;
      opacity: 1;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
        opacity: 1;
      }
    }
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    padding: 10px 15px;
    border: none;
    outline: 0;
    font-weight: 700;
    min-width: 135px;
    color: $colorAccent1;
    line-height: 24px;
    background-color: $white;
    border: 1px solid $white;

    &:hover{
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }
}


.link-text-btn {
  padding: 15px 0;
  padding-right: 45px;
  border-bottom: 3px solid $colorAccent3;
  color: $colorAccent3;
  font-family: $fontTitleSerif;
  text-transform: uppercase;
}
