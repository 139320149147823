// --------------------------------------------------
// Hero
// --------------------------------------------------

.hero-carousel {
  position: relative;
  height: 788px;
  color: $white;

  @media(max-width: 600px){
    height: 520px;
  }

  .hero-carousel-wrapper{
    position: relative;
    height: 100%;
  }

  .content-slider {
    @extend .site-max-width;
    position: relative;
    display: flex;
    margin-top: 120px;

    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    z-index: 5;

    //.site-btn {
    //  border: 1px solid $white;
    //
    //  &:hover {
    //    border: 1px solid $dark;
    //  }
    //
    //  &.is-revert {
    //    &:hover {
    //      border: 1px solid $dark;
    //    }
    //  }
    //}

    @media(max-width: 600px) {
      margin-top: 70px;
    }
  }

  .hero-carousel-slider{
    position: relative;
    height: 100%;

    .flickity-viewport{
      height: 100% !important;
    }
  }

  .hero-carousel-slider-item {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    img{
      width: 100%;
    }

    .vimeo-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -999;
      pointer-events: none;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($dark, .55);
        z-index: 5;
      }

      iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .hero-carousel-heading {
      position: relative;
      width: 100%;
      margin: 0 0 32px;
      z-index: 1;
      font-family: Magnita;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 61px;
      letter-spacing: 0.025em;
      text-align: left;
      align-self: flex-end;

      @media(max-width: 600px){
        margin-bottom: 35px;
        font-size: 28px;
      }

      @media(max-width: 420px){
        font-size: 20px;
      }

      @media(max-width: 420px){
        font-size: 16px;
      }

      p{
        margin: 0;
      }

      &.animate-line {
        strong {
          &:before {
            width: 100%;
          }
        }
      }
    }



    .hero-carousel-button{
      @extend .site-btn;
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      padding: 15px;

      @media(max-width: 420px){
        font-size: 14px;
      }
    }

    .video-button{
      position: relative;
      display: block;
      margin-bottom: 50px;
      width: 45px;
      height: 45px;
      padding: 15px;
      background-color: white;
      border-radius: 100%;

      transform: scale(1);
      transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955);

      @media(max-width: 600px){
        width: 30px;
        height: 30px;
        padding: 8px;
        margin-bottom: 30px;
      }

      &::after{
        content: '';
        position: absolute;
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        border: 1px solid $colorAccent1;
        border-radius: 100%;
      }

      img{
        margin-left: 2px;
      }

      &:hover {
        transform: scale(.85);
      }
    }
  }

  .scroll-down-indicator {
    position: absolute;
    left: 50%;
    bottom: -70px;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    color: $white;
    height: 70px;
    width: 70px;
    margin: 0 auto;
    border-radius: 70px;
    padding: 15px;
    z-index: 1;
    animation: button_pulse 2s ease-out infinite;

    @media(max-width: 767px){
      bottom: -30px;
    }

    // &:hover {
    //   animation: button_pulse 2s ease-out infinite;
    // }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      border: 1px solid #fff;
      font-size: 10px;
      font-weight: bold;
      color: white;

      @media(max-width: 767px){
        background-color: $white;
        color: $colorAccent1;
      }

      &:before {
        width: 2em;
      }
    }
  }

}

.basicLightbox__placeholder{
  width: 100%;

  iframe{
    width: 100%;
  }
}



.dom-loaded .hero-carousel .hero-carousel-sliderNav {
  opacity: 1;
}

.hero-carousel-sliderNav {
  @extend .site-max-width;
  display: flex;
  justify-content: center;

  position: relative;
  width: 100%;
  max-width: none;
  opacity: 1;
  transition: opacity 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  margin-top: 56px;
  margin-bottom: 100px;


  @media(max-width: 1024px) {
    justify-content: flex-start;
    overflow: scroll;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 54%;
    height: 1px;
    width: 100%;
    background-color: #1A1A1A;
    opacity: .1;
    transform: translate(0, -50%);
  }

  .flickity-viewport{
    overflow: visible;
  }

  .slider-item{
    position: relative;
    width: 11%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: .7;
    transition: all .3s ease-in-out;

    @media(max-width: 1270px){
      width: 20%;
    }

    @media(max-width: 840px){
      width: 25%;
    }

    @media(max-width: 600px){
      width: 33.33%;
      margin-left: 10px;
      margin-right: 10px;
    }

    @media(max-width: 450px){
      width: 50%;
    }

    &:hover {
      position: relative;
      opacity: 1;

      &:before {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        background: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
        top: 53%;
      }

      .img-container {

        &:after{
          opacity: 1;
        }
      }
    }


    .img-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: all .3s ease-in-out;

      img {
        min-height: 70px;
      }
    }

    .slider-item-heading{
      font-family: $fontNewText;
      margin: 40px 0 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
}
