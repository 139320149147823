// --------------------------------------------------
// Top Banner
// --------------------------------------------------
.top-banner {
  z-index: 999;
  display: none;
  width: 100%;
  padding: 19px 45px;
  background: $colorAccent1;
  color: $white;

  .top-banner-wrapper{
    @extend .site-max-width;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: 690px){
      padding: 0;
      flex-direction: column;
    }
  }

  &.is-active {
    display: block;
  }

  a {
    text-decoration: underline;
  }

  img{
    margin-right: 20px;

    @media(max-width: 690px){
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .top-banner-heading {
    margin: 0 50px 0 0;
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @media (max-width: 1059px) {
      font-size: 14px;
    }

    @media(max-width: 690px){
      margin-right: 0;
    }

    @media (max-width: 599px) {
      font-size: 13px;
    }
  }

  .banner-btn{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;

    color: $white;

    &:hover{
      text-decoration: underline;
    }

    &::after{
      content: "\E82B";
      position: relative;
      top: -1px;
      margin-left: 15px;
      font-family: fontello;
      font-size: 12px;
      color: white;

      &:hover{
        text-decoration: none;
      }
    }
  }

  .top-banner-close {
    position: absolute;
    right: 0;
    cursor: pointer;

    // @media (max-width: 767px) {
    //   right: 0;
    //   top: 20px;
    // }

    &:hover{
      opacity: .5;
      transition: opacity .5s;
    }
  }
}


// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  padding: 20px 0;
  z-index: 10;
  background: transparent;

  @media(max-width: 1024px){
    top: 0;
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, .15);
    background: $white;

    &.hide-nav-bar {
      -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
      transform: translate(0, -100%);
      -webkit-backface-visibility: hidden;
    }

    .header-logo {

      &.white {
        display: block;

        @media (max-width: 1024px) {
          display: block;
        }
      }

      &.dark {
        display: none;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      img {
        height: 55px;
        margin-top: -5px;
        margin-bottom: -5px;

        @media (max-width: 1024px) {
          height: 35px;
          margin-top: -5px;
          margin-bottom: -5px;
        }
      }
    }

    .header-nav {
      .header-link {
        color: $dark;
      }
    }
  }

  @media (max-width: 1400px) {
    padding: 40px 0;
  }

  @media (max-width: 1299px) {
    padding: 30px 0;
  }

  @media (max-width: 1100px) {
    padding: 20px 0;
  }

  .header-container {
    @extend .site-max-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo {
    &.white {
      display: none;

      @media (max-width: 1024px) {
        display: block;
      }
    }

    &.dark {
      display: block;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    img {
      height: 55px;
      margin-top: -5px;
      margin-bottom: -5px;

      @media (max-width: 1024px) {
        height: 35px;
        margin-top: -5px;
        margin-bottom: -5px;
      }
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    .header-link {
      position: relative;
      margin: 0 15px;
      display: flex;
      font-family: $fontNewText;

      align-items: center;
      text-align: center;
      letter-spacing: 0.1em;
      //text-transform: uppercase;
      color: $white;

      transition: all 0.35s ease;

      //font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;

      &:hover {
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
      }

      @media (max-width: 1400px) {
        font-size: 14px;
        margin: 0 10px;
      }

      &.is-active {
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-width: 2px;
        border-image-source: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-phone {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      margin: 0 20px 0 0;

      a {
        transition: all 0.35s ease;

        &:hover {
          color: $colorAccent1;
        }
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .language-link {
      color: #fff;
      margin-left: 15px;

      img {
        display: block;
        max-width: 30px;
      }

      @media (max-width: 1024px) {
        margin-right: 25px;
      }
    }

    .site-btn {
      margin-left: 15px;
      padding: 15px;
      @media (max-width: 1400px) {
        padding: 10px 20px;
        font-size: 12px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .mobile-menu-trigger {
      display: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
}

// --------------------------------------------------
// Sub Nav
// --------------------------------------------------
.subNav-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: $colorAccent5;
  color: $white;

  @media(max-width: 1024px){
    display: none;
  }

  .subNav{
    @extend .site-max-width;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-direction: row;
    justify-content: flex-end;

    .language-link {
      color: #fff;
      margin-left: 8px;

      img {
        display: block;
        max-width: 30px;
      }

      @media (max-width: 1024px) {
        margin-right: 25px;
      }
    }
  }

  .subNav-link{
    margin: 0 10px;
    display: flex;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    align-self: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    span {
      margin-left: 10px;
      padding-top: 1px;
      padding-left: 1px;
      width: 18px;
      height: 18px;
      display: flex;
      background: linear-gradient(to bottom, #8342FB, 65%, #00FFF0);
      border-radius: 18px;
      flex-direction: column;
      align-items: center;
    }

    &:last-child{
      margin-right: 0;
    }
  }
}
