// --------------------------------------------------
// Block
// --------------------------------------------------

.site-block {
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: 767px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

// --------------------------------------------------
// About nav
// --------------------------------------------------

.blocks-nav {
  @extend .site-max-width;
  @extend .site-block;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .blocks-nav-link {
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    background-color: #4c4c4c;
    transition: .5s;
    cursor: pointer;
  }
}

// --------------------------------------------------
// Blocks Arguments001
// --------------------------------------------------

.arguments001 {
  @extend .site-block;
  // padding: 150px 0;
  padding: 0;


  img {
    display: block;
    width: 100%;
    //margin-bottom: 20px;
  }

  .arguments001-wrapper {
    @extend .site-max-width;
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      width: 25%;
      text-align: center;
      border-right: 1px solid rgba($dark, .3);
      color: $dark;

      &:first-child {
        border-left: none !important;
      }

      &:last-child {
        border-right: none !important;
      }

      @media(max-width: 800px) {
        width: 50%;
        padding-bottom: 20px;
        border-right: none;

        &:last-child, &:nth-child(2) {
          border-left: 1px solid $lighter;
        }

        &:first-child, &:nth-child(2) {
          border-bottom: 1px solid $lighter;
        }

        &:last-child, &:nth-child(3) {
          padding-top: 20px;
        }

        .small-title {
          margin-bottom: 0;
        }
      }

      @media(max-width: 360px) {
        width: 100%;
        padding-top: 20px;
        border-bottom: 1px solid $lighter;

        &:first-child {
          border-top: 1px solid $lighter;
        }
      }

      &:first-child {
        border-left: 1px solid $lighter;
      }
    }

    .grid-item-stats, .hasMore {
      font-family: Raleway;
      font-size: 38px;
      margin: 0;
      display: inline-block;
    }

    .small-title {
      font-family: $fontNewText;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.05em;

      /* Grey tone */

      color: #1A1A1A;
    }
  }
}

// --------------------------------------------------
// Blocks grid003
// --------------------------------------------------

.grid003 {
  @extend .site-block;
  padding-top: 245px;
  color: $white;

  .grid003-wrapper {
    @extend .site-max-width;

    display: flex;
    flex-direction: column;
    align-items: center;

    .loader-half-circle-rotation {
      top: -114px;
      margin-left: .5px;
    }
  }

  .grid003-heading {
    max-width: 970px;
    margin: 0 auto 130px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 180%;
    text-align: center;
    letter-spacing: 0.1em;
    color: $dark;

    @media(max-width: 767px) {
      font-size: 16px;
      margin-bottom: 55px;
    }

    &:before{
      content: url("/img/site/lightning.svg");
      position: absolute;
      top: -100px;
      left: calc(50% - 14px / 2);
      display: block;
    }

    &:after{
      content: '';
      position: absolute;
      top: -113px;
      left: calc(50% - 64px / 2);
      display: block;
      width: 64px;
      height: 64px;
      border: 1px solid #8342FB;
      border-radius: 100%;
    }
  }

  .grid003-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .grid003-card {
    position: relative;
    width: calc(33.33% - 30px * 2);
    max-width: 350px;
    min-height: 440px;
    margin: 0 30px 130px 0;
    padding: 40px 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid $dark;

    @media(max-width: 1000px){
      width: calc(50% - 30px);
      margin-bottom: 70px;

      &:nth-child(odd){
        margin-right: 30px !important;
      }

      &:nth-child(even){
        margin-right: 0 !important;
      }
    }

    @media(max-width: 670px){
      width: 100%;
      max-width: none;
      min-height: 0;

      &:nth-child(odd){
        margin-right: 0 !important;
      }
    }

    &:hover{
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(89.65deg, #8342FB 0%, #00FFF0 100%);
      cursor: pointer;
    }

    @media(min-width: 1001px){
      &:nth-child(3n+3){
        margin-right: 0 !important;
      }
    }

    img{
      display: block;
      margin-bottom: 24px;

    }
  }

  .card-heading {
    font-family: $fontNewText;
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $dark;
  }

  .card-subHeading {
    font-family: $fontNewText;
    margin: 10px 0;
    min-height: 55px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: $dark;
  }
  .card-text {
    font-family: $fontNewText;
    min-height: 230px;
    color: #A9A9A9;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: $dark;

    @media(max-width: 670px){
      min-height: 0;
    }

    p{
      margin: 0;
    }
  }
  .card-subtext {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    color: #A9A9A9;
    color: $dark;
    padding-bottom: 60px;
  }

  .grid003-fake-btn {
    position: absolute;
    bottom: 40px;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 0);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
    font-family: $fontNewText;

    &:after{
      content: url(/img/site/icon-arrow.svg);
      position: relative;
      left: 10px;
      font-family: fontello;
      font-size: 28px;
    }
  }
}

// --------------------------------------------------
// Blocks grid004
// --------------------------------------------------

.grid004 {

  .grid004-wrapper {
    @extend .site-max-width;
  }

  .grid004-heading {
    text-align: center;
    font-size: 48px;
    font-weight: 400;
  }

  .grid004-container {
    display: flex;
    flex-wrap: wrap;

    .grid004-row {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      align-self: flex-start;

      @media(max-width: 1024px) {
        width: 100%;
      }

      .grid004-article {
        margin: 15px;
        padding: 15px;
        width: calc(50% - (15px * 2));
        background-size: cover;
        background-position: center;

        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, .45);
          z-index: 1;
        }

        @media(max-width: 500px) {
          width: 100%;
        }

        .grid004-article-heading {

        }

        .regular-text {
          color: #fff;
        }

        .article-content-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #fff;
          z-index: 2;
        }

        &.article-small {

        }

        &.article-large {
          width: 100%;
          min-height: 500px;
          text-align: center;

          @media(max-width: 500px) {
            min-height: 0;
            text-align: left;
          }

          .article-content-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Blocks Cta001
// --------------------------------------------------

.cta001 {
  @extend .site-block;;
  border-top: 1px solid #3fa2f7;
  border-bottom: 1px solid #3fa2f7;
  color: #3fa2f7;

  .cta001-wrapper {
    @extend .site-max-width;
    position: relative;

    .cta001-heading {
      margin-top: 0;
      text-align: center;
      font-size: 48px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .cta001-text {
      max-width: 920px;
      height: 90px;
      margin: 0 auto;
      overflow: hidden;
      line-height: 2;
      text-align: center;
      font-weight: 400;
    }

    .cta001-btn-container {
      width: 100px;
      margin: 0 auto;
    }

    .site-btn-alternate {
      text-align: center;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

// --------------------------------------------------
// Blocks cta003
// --------------------------------------------------

.cta003 {
  @extend .site-block;;
  text-align: center;
  color: rgb(63, 162, 247);

  .cta003-wrapper {
    @extend .site-max-width;

    .cta003-container {
      padding: 60px 0;
      background-color: rgba(63, 162, 247, .2);
      border-radius: 3px;

      @media(max-width: 600px) {
        padding: 60px 30px;
      }
    }

    .cta003-heading {
      margin-top: 0;
      font-size: 34px;
      font-weight: 400;
    }

    .cta003-text {
      max-width: 600px;
      margin: 0 auto 30px;
      line-height: 1.5;

      p {
        margin: 0;
      }
    }

    .site-btn {
      background-color: rgb(63, 162, 247);
      padding: 15px 30px;
      font-size: 16px;
      border: none;
      border-radius: 3px;
    }
  }
}

// --------------------------------------------------
// Blocks Cta004
// --------------------------------------------------

.cta004 {
  @extend .site-block;;
  border-top: 1px solid #3fa2f7;
  border-bottom: 1px solid #3fa2f7;
  color: #3fa2f7;

  .cta004-wrapper {
    @extend .site-max-width;
    position: relative;

    .cta004-heading {
      margin-top: 0;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
    }

    .cta004-text {
      margin: 0 auto;
      line-height: 1.7;
      text-align: center;
      font-weight: 400;
    }

    .cta004-content-container {
      max-width: 547px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }

    .cta004-btn-container {
      width: 100px;
      margin: 0 auto;
    }

    .site-btn {
      margin-top: 30px;
      padding: 15px 20px;
      background-color: rgb(63, 162, 247);
      font-size: 16px;
      border: none;
      border-radius: 3px;
    }
  }
}

// --------------------------------------------------
// Panels Row 001
// --------------------------------------------------

.block-panelsRow001 {
  @extend .site-max-width;

  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;
  padding-top: 75px;
  padding-bottom: 75px;
  background: #fff;
  text-align: center;

  @media (max-width: 1100px) {
    padding: 55px 0;
  }

  @media (max-width: 699px) {
    padding: 25px 0;
  }

  .panel {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    background: $colorAccent2;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .panel-left-col {
      width: 100%;
      max-width: 325px;
      padding: 45px 25px;
      padding-right: 0;
      text-align: center;

      @media (max-width: 699px) {
        padding: 25px 20px;
        padding-bottom: 0;
      }

      h3 {
        margin: 0;
        padding: 0;

        font-size: 22px;
        font-weight: 400;
        text-align: right;
        color: $colorTextLight;
        letter-spacing: 0.052em;
        text-transform: uppercase;

        @media (max-width: 1100px) {
          text-align: center;
        }
      }

      img {
        display: inline-block;
        margin: 0;
      }
    }

    .panel-right-col {
      width: 100%;
      padding: 45px 25px;
      margin: 0;
      text-align: left;
      color: $colorTextLight;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0;

      p {
        margin: 0 0 15px 0;
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .panel-logo {
      display: block;
      width: 70%;
    }

    &.panel2 {
      background: #fff;

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }

    &.panel3 {
      background: $grey;

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }

    &.white {
      background: #fff;
      border-top: 2px solid rgba(0, 0, 0, .10);

      &:first-child {
        border-top: none;
      }

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 001
// --------------------------------------------------

.block-teamGrid001{
  @extend .site-block;
  padding: 150px 0 120px 0;
  background-color: #1f1f1f;
  color: $white;

  @media(max-width: 1000px){
    padding-top: 175px;
  }

  @media(max-width: 500px){
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .teamGrid001-wrapper{
    @extend .site-max-width;
    max-width: 1110px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container-loader .value{
        background-image: linear-gradient(0deg, transparent 0%, #1f1f1f 0%), linear-gradient(0deg, white 0%, transparent 0%);
    }
    .container-loader .circle-on{
      background-color: #1f1f1f;
    }
  }

  .teamGrid001-heading{
    margin: 0 0 100px;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;

    @media(max-width: 1000px){
      margin-bottom: 50px;
    }
  }

  .teamGrid001-team-members{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;

    @media(max-width: 1000px){
      flex-direction: column;
    }
  }

  .team-member{
    position: relative;
    display: flex;
    width: 50%;
    max-width: 445px;
    flex-direction: column;

    @media(max-width: 1000px){
      width: 100%;
      max-width: none;
      margin-bottom: 50px;
    }

    &:nth-child(even){

      @media(max-width: 1000px){
        flex-direction: column !important;

        .team-member-header{
          flex-direction: column-reverse !important;
        }

        img{
          margin: 0 !important;
        }
      }

      .team-member-header{
        flex-direction: row-reverse;
      }

      img{
        margin: 0 60px 0 0;
      }
    }
  }

  .team-member-header{
    display: flex;
    margin-bottom: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media(max-width: 1000px){
      margin-bottom: 0;
      flex-direction: column-reverse;

      img{
        margin: 0 !important;
      }
    }

    img{
      margin-left: 60px;
      max-width: 160px;
      border-radius: 100%;
    }
  }

  .team-member-name{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1;
  }

  .team-member-jobtitle{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $colorAccent1;
  }

  .team-member-bio{
    height: 160px;
    padding-bottom: 15px;
    overflow: hidden;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.8;
  }

  .see-more-button{
    position: relative;
    margin-top: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: rgba(255, 255, 255, .5);
    text-align: center;

    &:hover{
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }

    &:before{
      content: '';
      position: absolute;
      top: -110px;
      right: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(31, 31, 31, 0.8) 33%, rgba(0, 0, 0, 0) 75%);
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
      height: 100px;
    }
  }
}
// --------------------------------------------------
// Block Team Grid 002
// --------------------------------------------------

.teamGrid002 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .teamGrid002-wrapper {
    @extend .site-max-width;

    .teamGrid002-heading {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
    }

    .teamGrid002-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .teamGrid002-grid-items {
      width: calc(33.33% - (10px * 2));
      margin: 10px;
      text-align: center;

      &:first-child, &:nth-child(3n+1) {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      @media(max-width: 650px) {
        width: 100%;
      }

      .grid-items-image {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .grid-items-heading {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .grid-items-position {
        min-height: 36px;
        margin-top: 10px;
        font-size: 14px;
        color: #90A0B0;

        @media(max-width: 650px) {
          min-height: 0;
          margin-bottom: 0;
        }
      }

      .regular-text {
        @media(max-width: 650px) {
          font-size: 16px;

          p {
            margin: 5px;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 002
// --------------------------------------------------

.teamGrid003 {
  @extend .site-block;;
  color: rgb(63, 162, 247);

  .teamGrid003-wrapper {
    @extend .site-max-width;

    .teamGrid003-carousel {
      width: 100%;
    }

    .flickity-viewport {
      height: 500px;
    }

    .teamGrid003-carousel-items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }

    .teamGrid003-image-wrapper {
      width: calc(50% - (40px * 2));
      margin-right: 40px;
      background-color: rgba(63, 162, 247, .2);

      @media(max-width: 1380px) {
        margin-right: 20px;
      }

      @media(max-width: 780px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        width: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    .teamGrid003-text-wrapper {
      width: 50%;
      margin-left: 40px;
      padding: 0 30px;

      @media(max-width: 1380px) {
        margin-left: 20px;
        padding: 0;
      }

      @media(max-width: 780px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;

        .teamGrid003-member-jobTitle {
          margin: 0 0 20px 0;
        }
      }

      .teamGrid003-member-heading {
        margin: 0 0 10px 0;
        font-weight: 400;
        font-size: 52px;
        line-height: 58px;
      }

      .teamGrid003-member-jobTitle {
        margin: 0 0 20px 0;
      }

      .teamGrid003-member-bio {
        line-height: 1.8;
      }
    }
  }

  .teamGrid003-sliderNav {
    margin-top: 60px;
    background-color: rgba(63, 162, 247, .2);

    .teamGrid003-sliderNav-wrapper {
      @extend .site-max-width;
      position: relative; //    padding: 30px 0;

      // @media(max-width: 600px){
      //   padding: 30px 0;
      // }
    }

    .teamGrid003-sliderNav-carousel {
      position: relative;
    }

    .flickity-viewport {
      max-height: 160px;
      top: -13px;
      padding-top: 15px;
    }

    .is-selected-arrow {
      position: absolute;
      top: -7px;
      left: 176px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(63, 162, 247, .2);
    }

    .slider-item {
      width: 16.667%;
      padding: 20px 60px;

      @media(max-width: 1130px) {
        width: 20%;
      }

      @media(max-width: 990px) {
        width: 25%;
        padding: 20px 40px;
      }

      @media(max-width: 600px) {
        width: 33.33%;
        padding: 10px 30px;
      }

      &.is-selected {
        &:before {
          content: '';
          position: absolute;
          top: -15px;
          left: 50%;
          margin-left: -8px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 13px 13px 13px;
          border-color: transparent transparent rgba(63, 162, 247, .2) transparent;
        }
      }

      img {
        width: 100%;
        border-radius: 100%;
      }
    }
  }
}

// --------------------------------------------------
// Timeline 001
// --------------------------------------------------

.block-timeline001 {
  padding: 0;
  margin: 0;

  .timeline-wrapper {
    background-image: linear-gradient(to top, $colorAccent1, $colorTextDark, $colorAccent2 70%);
  }

  .timeline-content {
    @extend .site-max-width;

    display: block;
    width: 100%;

    padding-top: 75px;

    @media (max-width: 1100px) {
      padding-top: 45px;
    }

    .timeline-date {
      @extend .main-text;
    }

    .timeline-title {
      @extend .title4;
      margin-bottom: 15px;
      color: #fff;

      @media (max-width: 699px) {
        margin-bottom: 10px;
      }
    }

    .timeline-desc {
      @extend .main-text;

      font-size: 20px;
      line-height: 1.4;
      font-weight: 700;
      color: #fff;

      p {
        color: #fff;
      }

      @media (max-width: 699px) {
        margin-bottom: 10px;
      }
      @media(max-width: 1050px) {
        font-size: 16px;
      }
    }
  }

  .timeline-row {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 40px;

    @media (max-width: 699px) {
      flex-direction: column;
      padding-left: 60px;
      padding-bottom: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 4px;
      height: 100%;
      margin-left: -1.5px;
      background-color: $white;

      @media (max-width: 699px) {
        left: 10px;
        margin-left: 2.5px;
      }
    }

    &:first-of-type {
      &:before {
        top: calc(50% - 35px);
        height: calc(50% + 35px);
      }
    }

    &:last-of-type {
      &:before {
        height: calc(50% - 35px);
      }
    }

    .timeline-row-img {
      width: calc(50% - 60px);

      @media (max-width: 699px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .timeline-row-dot {
      position: relative;
      margin: 0 45px;

      @media (max-width: 699px) {
        position: absolute;
        left: 0;
        top: calc(50% - 80px);
        width: 24px;
        height: 24px;
        border-width: 3px;
        margin: 0;
      }
    }

    .timeline-row-content {
      color: #fff;
      width: calc(50% - 60px);

      @media (max-width: 699px) {
        width: 100%;
      }
    }

    &:nth-child(odd) {
      .timeline-row-img {
        order: 3;

        @media (max-width: 699px) {
          order: 2;
        }
      }

      .timeline-row-dot {
        order: 2;

        @media (max-width: 699px) {
          order: 1;
        }
      }

      .timeline-row-content {
        order: 1;
        text-align: left;

        @media (max-width: 699px) {
          order: 3;
          text-align: left;
        }
      }
    }
  }

  %clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /* Loader 2 */
  .loader-container {
    position: relative;
    width: 100%;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0px auto;
    width: 200px;
  }

  .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes color {
    100%, 0% {
      stroke: #E1E1E1;
    }
    40% {
      stroke: #CCCCCC;
    }
    66% {
      stroke: #AEAEAE;
    }
    80%, 90% {
      stroke: #CCCCCC;
    }
  }

  .beacon-container {
    position: relative;
  }

  .beacon {
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

    &:after,
    &:before {
      position: absolute;
      display: block;
      background-color: transparent;
      transform-origin: 50% 50%;
    }

    &:after,
    &:before {
      content: '';
      top: -1px;
      left: -1px;
      border: 1px solid $white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transform: scale(1.3, 1.3);
    }

    &:before {
      animation: pulse 2s infinite;
    }

    &:after {
      animation: pulse 2s infinite .7s;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-moz-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-o-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }


}

// --------------------------------------------------
// Block pricing001
// --------------------------------------------------

.pricing001 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .pricing001-wrapper {
    @extend .site-max-width;
    text-align: center;

    .pricing001-heading {
      font-size: 48px;
      font-weight: 400;
    }

    .pricing001-grid {
      display: flex;
      flex-wrap: wrap;

      .pricing001-grid-items {
        width: 33.33%;
        padding: 35px 40px;
        border: 2px solid rgba(0, 0, 0, .1);

        &:first-child, &:nth-child(2) {
          border-right: none;
        }

        @media(max-width: 855px) {
          width: 100%;

          &:first-child, &:nth-child(2) {
            border-bottom: none;
            border-right: 2px solid rgba(0, 0, 0, .1);
          }
        }
      }

      .grid-items-header {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        @media(max-width: 855px) {
          padding-bottom: 0;
        }
      }

      .grid-items-heading {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 400;
      }

      .grid-items-shortDescription {
        margin: 0;
        font-size: 14px;
      }

      .grid-items-description {
        margin-bottom: 0;
        font-size: 14px;

        p {
          min-height: 72px;
          margin-bottom: 0;
        }
      }

      .grid-items-price {
        font-weight: 400;
        font-size: 60px;
        margin: 30px 0;
      }

      .grid-items-list {
        padding: 0;
        list-style: none;
      }

      .grid-items-list-items {
        padding: 15px 0;
      }

      .site-btn {
        padding: 10px 35px;
        font-size: 16px;
      }
    }
  }
}

// --------------------------------------------------
// Block pricing002
// --------------------------------------------------

.pricing002 {
  padding: 20px 0;
  color: rgb(63, 162, 247);

  .pricing002-wrapper {
    @extend .site-max-width;

    .pricing002-heading {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
    }

    .pricing002-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .grid-items {
      width: calc(50% - (20px * 2));
      margin: 10px;
      padding: 20px;
      border: 1px solid rgba(63, 162, 247, .1);

      @media(max-width: 800px) {
        width: 100%;
      }

      &:nth-child(odd) {
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-right: 0;
      }

      .grid-items-heading {
        display: inline-block;
      }

      .grid-items-pricing {
        display: inline-block;
        float: right;
      }

      .pricing-bar-container {
        position: relative;
        width: 100%;

        &:focus {
          outline: 0;
        }

        .value-bar {
          position: absolute;
          top: 2px;
          width: 35%;
          height: 10px;
          display: block;
          z-index: 1;
          background-color: rgb(63, 162, 247);

          &:focus {
            outline: 0;
          }
        }

        input[type="range"] {
          -webkit-appearance: none;
          width: 100%;
          height: 10px;
          position: absolute;
          top: 2px;
          background-color: rgba(63, 162, 247, .1);
          z-index: 5;

          &:focus {
            outline: 0;
          }
        }

        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: rgb(63, 162, 247);
          position: relative;
          z-index: 3;
          cursor: pointer;

          &:focus {
            outline: 0;
          }
        }
      }

      .pricing-bar-values {
        display: flex;
        margin-top: 20px;

        span {
          position: relative;
          width: 20%;
          margin-top: 10px;
          margin-right: 5px;
          text-align: right;

          &:before {
            position: absolute;
            top: -13px;
            content: "|";
            font-size: 10px;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }


    }

    .pricing002-total-container {
      .pricing002-total-price {
        font-size: 38px;
        text-align: center;
      }
    }
  }
}

// --------------------------------------------------
// Split text image
// --------------------------------------------------

.block-split-text-image {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;

  @media only screen and (max-width: 599px) {
    flex-wrap: wrap;
  }

  &.is-left {
    flex-direction: row-reverse;
  }

  .block-content {
    position: relative;
    width: 75%;
    padding: 75px;

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    &.is-collapsed {
      &:after {
        content: '';
        position: absolute;
        top: 65%;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
        z-index: 2;
      }
    }

    .regular-text {
      // margin-bottom: 75px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    h2 {
      @extend .big-title;
      //color: $dark;
      line-height: 1.4;

      @media only screen and (max-width: 767px) {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
    }

    h3 {
      @extend .big-title;
      color: $dark;
      font-size: 26px;
      line-height: 1.4;
    }

    h4 {
      @extend .big-title;
      color: $dark;
      font-size: 20px;
      line-height: 1.4;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    figure {
      padding: 0;
      margin: 0;
    }

    blockquote {
      padding: 0 0 0 25px;
      margin: 25px 0;
      border-left: 4px solid $lighter;
    }

    .read-more {
      display: none;
      position: absolute;
      right: 75px;
      bottom: 25px;
      z-index: 3;
      padding: 5px 0;
      font-size: 14px;
    }
  }

  .block-image {
    width: 25%;

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    img {
      display: block;
    }
  }
}

// --------------------------------------------------
// Block - Image
// --------------------------------------------------

.block-image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// --------------------------------------------------
// Block - Partners
// --------------------------------------------------

.block-partners {
  padding: 0;

  .grid-text {
    width: 100%;
    text-align: center;

    @media only screen and (max-width: 767px) {
    }
  }

  .big-title {
    position: relative;
    width: 100%;
    text-align: center;
    font-family: $fontTitle;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 0 50px;
    color: black;

    @media (max-width: 650px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1.5px;
      margin-bottom: 25px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      width: 100%;
      height: 1px;
      background-color: rgba(#262626, .2);
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background-color: $colorAccent1;

      @media (max-width: 650px) {
        height: 2px;
        bottom: -10px;
      }
    }

    span {
      position: relative;
      z-index: 2;
      padding: 0 15px;
      background-color: $grey;

      &.white-bg {
        background-color: $white;
      }
    }
  }

  .partners-cta {
    background-color: $grey;
    padding: 80px 20px;

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    .partners-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1520px;
      margin: 0 auto;

      &.page {
        .partners-list {
          &:not(.big) {
            margin-bottom: 40px;
          }

          .partial-partner {
            margin-bottom: 40px;
          }
        }
      }
    }

    .partners-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1100px) {
        flex-wrap: wrap;
      }

      @media (max-width: 650px) {
        flex-direction: column;
      }

      &.big {
        .partial-partner {
          width: calc(50% - 10px);
          min-height: 250px;

          @media (max-width: 1100px) {
            width: calc(50% - 10px);
            margin-bottom: 20px;
            min-height: 170px;
          }

          @media (max-width: 650px) {
            width: 100%;
            min-height: 140px;
          }
        }
      }
    }

    .partial-partner {
      width: calc(25% - ((20px * 3) / 4));
      height: 170px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media (max-width: 1100px) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
      }

      @media (max-width: 650px) {
        width: 100%;
        min-height: 140px;
      }

      img {
        width: 70%;
        max-width: 70%;
      }
    }

    .site-btn {
      margin-top: 50px;

      @media (max-width: 1100px) {
        margin-top: 30px;
      }
    }
  }
}

// --------------------------------------------------
// Block - Testimonials001
// --------------------------------------------------

.testimonials {
  @extend .site-block;;
  color: #3fa2f7;

  .testimonials-heading-wrapper {
    margin-bottom: 60px;
  }

  .testimonials-heading {
    margin-top: 0;
    text-align: center;
    font-size: 52px;
    font-weight: 300;
    line-height: 58px;
  }

  .testimonials-slider {
    .flickity-viewport {
      height: 380px;
    }

    .is-selected {
      opacity: 1 !important;

      .slider-skeleton, .slider-skeleton p {
        color: inherit !important;
        background: linear-gradient(to top, transparent, transparent) repeat-x !important;
      }
    }

    .testimonials-slider-item {
      width: 100%;
      max-width: 524px;
      margin: 0 auto;
      padding: 0 10px;
      opacity: .5;
      border-radius: 3px;
      color: #3fa2f7;

      .testimonials-cards {
        padding: 50px;
        line-height: 25px;
        border: 1px solid #e4f1fd;
      }

      .testimonials-item-heading {
        font-size: 19px;
        font-weight: 400;
        margin: 0;

        .slider-skeleton {
          color: transparent;
          /* background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x; */
          background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
          background-size: 1px 12px;
          background-position: center;
          -webkit-transition: 0.5s ease all;
          transition: 0.5s ease all;
        }
      }

      .testimonials-infos {
        display: inline-block;
        font-size: 14px;
      }

      .testimonials-rating {
        display: inline-block;

        // .testimonials-rating-items{
        //   display: block;
        //   background-color: #3fa2f7;
        //   width: 20px;
        //   height: 20px;
        // }
      }

      .testimonials-item-date {
        margin: 0;

        .slider-skeleton {
          color: transparent;
          background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x;
          background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
          background-size: 1px 12px;
          background-position: center;
          -webkit-transition: 0.5s ease all;
          transition: 0.5s ease all;
        }
      }

      .testimonials-text {
        margin: 10px 0 0 0;

        .slider-skeleton {
          p {
            display: inline;
            color: transparent;
            background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x;
            background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
            background-size: 1px 12px;
            background-position: center;
            -webkit-transition: 0.5s ease all;
            transition: 0.5s ease all;
          }

        }
      }
    }
  }

  .flickity-prev-next-button {
    @media(min-width: 650px) {
      display: none;
    }

    .flickity-button-icon {
      fill: #3fa2f7;
    }
  }
}

// --------------------------------------------------
// Block - Testimonials002
// --------------------------------------------------

.testimonials002 {
  @extend .site-block;
  position: relative;
  padding: 50px 0;
  padding-top: 0;
  //background-color: $black;
  color: $white;
  margin-top: 50px;

  @media(max-width: 767px) {
    padding: 20px 0;
    padding-top: 0;
    margin-top: 20px;
  }

  &:before{
    content: '';
    position: absolute;
    bottom: 50%;
    right: 50%;
    top: 0;
    border: .5px solid rgba(255, 255, 255, .3);
    opacity: .5;

    @media(max-width: 767px){
      bottom: calc(100% - 46px);
      display: none;
    }
  }

  .testimonials002-wrapper {
    @extend .site-max-width;
    max-width: calc(1110px - 45px * 2);
    border-bottom: 1px solid rgba(255, 255, 255, .3);

  }

  .testimonials002-title {
    text-align: center;
    margin-top: 0;
    font-size: 52px;
    font-weight: 300;
    line-height: 58px;
  }

  .testimonials002-slider {
    width: 100%;
    max-width: 730px;
    margin: 0 auto 40px;
    text-align: center;

    .flickity-viewport {
      height: 200px;
    }

    .flickity-slider {
      display: flex;
      align-items: center;
    }

    .flickity-prev-next-button{
      width: 44px;
      height: 44px;
      background: #F6F6F6;
      /* Grey tone */

      border: 1px solid #1A1A1A;
    }

    .previous{
      left: -140px;
    }

    .previous:before{
      content: "\E823";
      position: relative;
      top: -1px;
      font-family: fontello;
      font-size: 12px;
      color: $dark;
    }

    .next{
      right: -140px;
    }

    .next:before{
      content: "\E82B";
      position: relative;
      top: -1px;
      font-family: fontello;
      font-size: 12px;
      color: $dark;
    }

    .testimonials002-slider-item {
      width: 100%;
      display: flex;
      padding: 40px;

      flex-direction: column;
      background: #F6F6F6;

      border: 1px solid #1A1A1A;

      @media(max-width: 550px){
        padding: 20px;
      }

      img{
        max-width: 230px;
        display: block;
        margin: 0 auto 30px;
      }

      .testimonials002-text {
        position: relative;
        display: inline-block;
        max-width: 500px;
        font-family: $fontNewText;
        font-style: italic;
        font-weight: normal;
        font-size: 16px;
        line-height: 180%;
        /* or 29px */

        text-align: center;


        margin: 0 auto;

        color: $dark;

        p{
          margin: 0;
        }

        strong{
          font-weight: normal;
        }
      }
    }
  }

  .testimonials002-sliderNav {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;

    .is-nav-selected{
      position: relative;
      opacity: 1 !important;

      .slider-item-name{
        font-size: 18px;
        color: $dark;
      }

      .slider-item-jobTitle{
        font-size: 14px;
        color: $dark;
      }

      .slider-item-heading{
        font-size: 18px;
        color: $dark;
      }
    }

    .slider-item {
      width: 33.33%;
      height: 113px;
      text-align: center;
      opacity: .5;

      @media(max-width: 550px) {
        width: 50%;
      }
    }



    .slider-item-name{
      margin: 0 0 5px;
      font-family: $fontNewText;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 180%;
      text-align: center;
      color: rgba($dark, .3);
    }

    .slider-item-jobTitle{
      margin: 0 0 10px;
      font-family: $fontNewText;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: rgba($dark, .3);
    }

    .slider-item-heading{
      margin: 0 0 30px;
      font-family: $fontNewText;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.05em;
      color: rgba($dark, .3);
    }
  }
}

// --------------------------------------------------
// Block - Images001
// --------------------------------------------------

.intro-image-wrapper {
  padding: 0;
  position: relative;
  display: flex;
  max-width: 1440px;

  margin: 0 auto;

  @media(max-width: 1024px){
    flex-direction: column;
    padding: 20px 100px;
  }

  @media(max-width: 768px) {
    flex-direction: column;
    margin-bottom: 100px;
    padding: 50px 20px;
  }

  @media(max-width: 599px) {
    flex-direction: column;
    margin-bottom: 0;
    padding: 50px 20px;
  }

  .image-wrapper-single {
    position: relative;
    margin-right: 10px;

    @media(max-width: 1024px) {
      margin-right: 0;
    }


    .block-present {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: $white;
      font-family: $fontNewText;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 180%;
      background-color: rgba(#080808, .5);
      padding: 10px 20px;

      @media(max-width: 760px) {
        bottom: 0;
        right: 0;
        font-size: 10px;
      }
    }
  }

  img {
    display: block;

    min-width: 820px;
    min-height: 710px;

    width: 100%;
    height: 100%;
    margin-right: 1%;

    @media(max-width: 1324px) {
      min-width: 50%;
      min-height: inherit
    }

    @media(max-width: 1024px){
      width: 100%;
      height: auto;
      min-width: inherit;
      min-height: inherit;

    }

    @media(max-width: 768px) {
      margin-right: inherit;
      margin-bottom: 1%;
    }

    @media(max-width: 599px) {
      margin-right: inherit;
      margin-bottom: 10px;
    }
  }

  .image-double {
    width: 50%;

    @media(max-width: 1024px) {
      width: 100%;
    }

    .image-wrapper{
      position: relative;
      background-size: cover;

      .block-present {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: $white;
        font-family: $fontNewText;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 180%;
        background-color: rgba(#080808, .5);
        padding: 10px 20px;

        @media(max-width: 760px) {
          bottom: 0;
          right: 0;
          font-size: 10px;
        }

        @media (min-width: 1325px) and (max-width: 1440px) {
          right: 105px;
        }
      }
    }

    .image-wrapper:first-child{
      margin-bottom: 13px;
    }

    img {
      display: block;

      min-width: 610px;
      min-height: 353px;
      height: calc(50% - 1%);
      opacity: 0;

      @media(max-width: 1324px) {
        min-width: 510px;
        min-height: 253px;
      }

      @media(max-width: 1024px) {
        width: 100%;
        height: inherit;
        min-width: inherit;
        min-height: inherit;
      }


      &:first-child {

        @media(max-width: 1024px) {
          margin-top: 1%;
          margin-bottom: 1%;
        }

        @media(max-width: 599px) {
          margin-right: inherit;
          margin-bottom: 10px;
        }
      }
    }
  }

  .block-text{
    position: absolute;
    top: 10%;
    left: 5%;
    max-width: 420px;
    padding: 50px 35px;
    background-color: rgba($black, 0.8);
    //color: $white;
    border-left: 4px solid $colorAccent1;
    z-index: 2;

    @media(max-width: 768px) {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    @media(max-width: 599px) {
      background-color: rgba($black, 0.8);
    }

    .block-text-heading{
      margin: 0 0 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      align-items: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $white;

    }

    .block-text-text{
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.8;
      color: $white;

      @media(max-width: 599px) {
        font-size: 14px;
        line-height: 1.2;
      }

      strong{
        font-weight: 900;
      }
    }
  }
}

// --------------------------------------------------
// Block - Icons001
// --------------------------------------------------

.text001{
  @extend .site-block;
  padding: 150px 0;
  //color: $white;

  @media(max-width: 560px){
    padding: 70px 0;
  }

  .text001-wrapper{
    @extend .site-max-width;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .text001-heading{
    margin: 0 0 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.8;

    @media(max-width: 560px){
      margin: 0 0 20px;
      font-size: 26px;
    }
  }

  .text001-text{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.8;

    p{
      margin: 0 0 50px;
      max-width: 450px;
      font-weight: 400;

      @media(max-width: 560px){
        margin: 0 0 20px;
      }

      &:first-child{
        max-width: 540px;
      }
    }

    strong{
      font-weight: bold;
    }
  }

  .icon-section{
    position: absolute;
    right: 20%;
    top: 30%;
    opacity: .5 !important;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    &::before{
      content: '';
      position: absolute;
      width: 615px;
      display: block;
      border: 1px solid #FFFFFF;
      height: 615px;
      left: -230px;
      top: -130px;
      border-radius: 100%;
    }

    img{
      position: relative;
      width: 100%;


    }
  }
}

// --------------------------------------------------
// Block - Icons001
// --------------------------------------------------

.icon001 {
  @extend .site-max-width;
  max-width: 1110px;
  padding: 100px 20px;
  padding-bottom: 100px;

  @media(max-width: 1024px) {
    padding: 100px 20px;
  }

  @media(max-width: 767px) {
    padding: 50px 20px;
  }

  // @media(max-width: 599px) {
  //   padding: 20px;
  // }

  .icon001-wrapper {
    display: flex;
    margin-bottom: 50px;
    justify-content: space-around;
    align-items: self-start;

    @media(max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .icon001-self {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% / 5);

      @media(max-width: 1440px) {
        width: calc(100% / 3);
        margin-bottom: 30px;
      }
      @media(max-width: 1024px) {
        width: calc(100% / 2);
      }
      @media(max-width: 767px) {
        width: 100%;
      }

      img {
        display: block;
        opacity: .7;
        height: 50px;
        margin-bottom: calc(30px - 18px);
      }

      p {
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        /* identical to box height */
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        //color: $white;

        @media (max-width: 599px) {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }

  .icon001-text {
    margin: 0 auto;
    //color: $white;
    max-width: 350px;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    text-align: center;


    @media (max-width: 599px) {
      width: 100%;
      font-size: 14px;
    }

    p {
      font-weight: normal;
      margin: 0;

        strong {
          font-weight: bold;
        }
    }
  }
}

// --------------------------------------------------
// Block - Slider005
// --------------------------------------------------

.slider005 {
  @extend .site-max-width;

  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  background: #F7F7F7;

  @media(max-width: 767px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .slider005-slider {
    .flickity-button {

      &.flickity-prev-next-button{
        &.previous,
        &.next {

          height: 100%;
          width: 100%;
          max-width: 100px;
          max-height: 200px;
          border-radius: 0;

          @media(max-width: 1045px) {
            max-width: 100px;
          }

          @media(max-width: 764px) {
            max-width: 50px;
          }

          &:before {
            color: $dark;
            border: 1px solid $dark;
            padding: 18px 17px;
            border-radius: 50%;
            font-size: 8px;
          }
        }

        &.previous {
          //background: linear-gradient(90deg, $black 0%, rgba($black, 0) 100%);
          background: transparent;

          @media(max-width: 1045px) {
            left: 0;
          }

          &:before {
            content: '\E823';
            right: 70px;
            @media(max-width: 1045px) {
              right: 0;
            }
          }
        }

        &.next {
          //background: linear-gradient(270deg, $black 0%, rgba($black, 0) 100%);
          background: transparent;

          @media(max-width: 1045px) {
            right: 0;
          }

          &:before {
            content: '\E82B';
            left: 70px;
            @media(max-width: 1045px) {
              left: 0;
            }
          }
        }
      }
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px 18px 50px 0;
      opacity: 1;
      width: 100%;
      height: 100%;
      max-width: 180px;
      max-height: 180px;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid $dark;

      &:hover {
        .logo-infos {
          display: block;
        }
      }

      &.is-dark {
        opacity: .6;
      }

      &.is-light {
        opacity: 1;
        border: none;
      }

      .logo-link {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 160px;
        min-height: 160px;
        border-radius: 50%;
        background-color: $white;

        img {
          display: block;
          max-width: 100px;
        }
      }

      .logo-infos {
        display: none;
        position: absolute;
        bottom: -50px;
        left: 50%;
        min-width: 150px;
        background-color: $dark;
        padding: 10px;
        transform: translate(-50%, 0);
        border-radius: 4px;
        z-index: 9999;


        &:before {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          top: -15px;
          right: 50%;
          transform: translate(50%, 0);
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid $dark;
        }

        .logo-title {
          display: block;
          margin: 0;
          color: $white;
          font-family: $fontNewText;
          font-weight: 500;
          text-align: center;
        }

        .logo-desc {
          display: block;
          margin: 0;
          color: $white;
          font-family: $fontNewText;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
}

.career-index {
  .text001 {
    background-color: #f7f7f7;
  }
}

.intellectual-properties {
  .block-intro001 {
    background-color: #f7f7f7;
  }

  .schema-content {
    padding: 20px;
    padding-bottom: 100px;
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    .schema-text {
      p {
        margin: 0;
        margin-top: 56px;
        text-align: center;
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */

        letter-spacing: 0.05em;

        /* Grey tone */

        color: #1A1A1A;

        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.products, .how-it-work {
  .split001 {
    .button-container {
      @media (max-width: 765px) {
        flex-wrap: wrap;
        padding: 25px 10px 30px;
        margin-bottom: 30px;
        margin-top: 30px;
      }



      a {
        @media (max-width: 765px) {
          max-width: 88%;
        }
      }

      > img:first-child {
        @media (max-width: 765px) {
          flex: 1 1 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.how-it-work {
  .split001 {
    .button-container {
      padding-top: 100px;

      @media (max-width: 765px) {
        flex-wrap: wrap;
        padding: 25px 10px 30px;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      a {
        @media (max-width: 765px) {
          max-width: 88%;
        }
      }

      > img:first-child {
        @media (max-width: 765px) {
          flex: 1 1 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .block-intro001 {
    &.longbar {
      min-height: inherit;
      padding-bottom: 0;

      &:before {
        display: none;
      }

      .block-wrapper {
        .intro-title {
          margin-bottom: 0;
        }
      }


    }
  }
  .slider001 {
    padding-top: 0;
  }
}

.homepage {
  .block-intro001 {
    padding-bottom: 0;
  }
}

.vimeo001 {
  position: relative;
  height: 788px;
  color: $white;

  @media(max-width: 767px) {
    max-height: 500px;
    padding-top: 56.25%;
  }

  .vimeo-block {
    position: relative;
    height: 100%;

    .content-vimeo {
      @extend .site-max-width;
      position: relative;
      display: flex;

      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      z-index: 5;
      height: 100%;
      width: 100%;

      .vimeo-heading {
        position: relative;
        width: 100%;
        margin: 0 0 32px;
        z-index: 1;
        font-family: Magnita;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 61px;
        letter-spacing: 0.025em;
        text-align: left;
        -ms-flex-item-align: end;
        align-self: flex-end;

        @media (max-width: 767px) {
          font-size: 16px;
          margin-bottom: -40px;
          line-height: 1.2;
        }
      }

      .btn-wrapper {
        //position: absolute;
        //bottom: 60px;
        //left: 0;
        display: flex;
        width: 100%;
      }
    }




    .vimeo-wrapper {
      position: relative;
      z-index: -999;
      pointer-events: none;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($dark, .55);
        z-index: 5;
      }

      iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;

        @media(max-width: 767px) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

  }
}



